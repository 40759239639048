<template>
  <div>
    <b-modal id="editDonation" @hidden="clearData" size="xl" no-close-on-backdrop>
      <div slot="modal-header">
        <h2 class="h1-like">{{ $t("dashboard.edit_edit_donation") }} #{{ donationId }}</h2>
        <div class="modal-header__hide" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
          {{ $t("dashboard.donation_edit_cancel") }}
        </button>
        <button
          form="donation-edit"
          class="bttn bttn--lg bttn--orange"
          :disabled="loading || loadingDonation || !teamDonationIsValid"
          @click.prevent="editDonate"
        >
          {{ $t("dashboard.donation_edit_save") }}
        </button>
      </div>

      <b-overlay :show="loadingDonation" :variant="loadingDonationOverlayVariant">
        <b-tabs content-class="m-0 p-0 mt-3" v-model="tabs">
          <b-tab :title="$t('dashboard.tab_default_form')" active>
            <form id="donation-edit" class="entities-data">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label class="entities-data__label"><strong>Status: </strong></label>
                    {{ editDonation.data.attributes.status }}
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label class="entities-data__label"><strong>Details: </strong></label>
                    {{ editDonation.data.attributes.failure_reason }}
                  </div>
                </div>
              </div>

              <error v-if="errors" :errors="errors"></error>

              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="entities-data__label entities-data__label--required">
                      {{ $t("dashboard.edit_donate_display_name") }}
                    </label>
                    <b-form-input
                      name="display_name"
                      v-model="editDonation.data.attributes.display_name"
                      v-validate="'required'"
                      :class="{ 'is-danger': vErrors.has('display_name') }"
                    />
                    <div class="form-help-grid">
                      <span v-show="vErrors.has('display_name')" class="v-help is-danger">
                        {{ $t("template.type_is_required", "Field is required") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label
                      class="entities-data__label"
                      :class="{
                        'entities-data__label--required':
                          $_emailGettersMixin_getEmailValidations[0],
                      }"
                    >
                      {{ $t("dashboard.edit_donate_email") }}
                    </label>
                    <b-form-input
                      name="email"
                      :value="$_emailGettersMixin_getStaticEmail(editDonation.data)[0]"
                      @input="editDonation.data.attributes.email = $event"
                      v-validate="$_emailGettersMixin_getEmailValidations[1]"
                      :class="{ 'is-danger': vErrors.has('email') }"
                    />
                    <div class="form-help-grid">
                      <span v-show="vErrors.has('email')" class="v-help is-danger">
                        {{ $t("template.email_is_required") }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="entities-data__label entities-data">
                      {{ $t("donors.offline_donation_source") }}
                    </label>
                    <b-form-select
                      v-model="editDonation.data.attributes.offline_donation_source"
                      :options="offlineDonationSourceOptions"
                    >
                    </b-form-select>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="entities-data__label entities-data">
                      {{ $t("donors.offline_donation_note") }}
                    </label>
                    <b-form-input v-model="editDonation.data.attributes.offline_donation_note">
                    </b-form-input>
                  </div>
                </div>
              </div>
              <div class="row" v-show="bankBranchAccountCanBeShowed">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="entities-data__label entities-data">
                      {{ $t("dashboard.add_donate_bank") }}
                    </label>
                    <b-form-input v-model="editDonation.data.attributes.bank"></b-form-input>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="entities-data__label entities-data">
                      {{ $t("dashboard.add_donate_branch") }}
                    </label>
                    <b-form-input v-model="editDonation.data.attributes.branch"></b-form-input>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="entities-data__label entities-data">
                      {{ $t("dashboard.add_donate_account") }}
                    </label>
                    <b-form-input v-model="editDonation.data.attributes.account"></b-form-input>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="entities-data__label entities-data">
                      {{ $t("dashboard.add_donate_check_n") }}
                    </label>
                    <b-form-input v-model="editDonation.data.attributes.check_n"></b-form-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div v-if="showEeffectiveDate" class="col-12 col-md-6">
                  <div class="form-group form-unit form-unit--addon date-time-select">
                    <label class="entities-data__label">
                      {{ $t("dashboard.offline_donate_effective_date", "Effective date") }}
                    </label>
                    <div class="form-unit__addon-wrap">
                      <div class="form-unit__addon-icon">
                        <svg viewBox="0 0 24 22">
                          <g fill="none" fill-rule="evenodd" opacity="1">
                            <path
                              stroke="#107598"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4.64 1v3.64M19.18 1v3.64M1 2.82V21h14.55l7.27-7.27V2.82zM21.91 15.09v5h-5"
                            />
                            <path
                              fill="#107598"
                              fill-rule="nonzero"
                              d="M1 2.82V21h14.55l7.27-7.27V2.82H1zm6.36 14.54H5.55v-1.81h1.81v1.81zm0-3.63H5.55V11.9h1.81v1.82zm0-3.64H5.55V8.27h1.81v1.82zM11 17.36H9.18v-1.81H11v1.81zm0-3.63H9.18V11.9H11v1.82zm0-3.64H9.18V8.27H11v1.82zm3.64 7.27h-1.82v-1.81h1.82v1.81zm0-3.63h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82zm3.63 3.64h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82z"
                            />
                          </g>
                        </svg>
                      </div>

                      <datetime
                        input-id="effective-date"
                        type="datetime"
                        value-zone="UTC"
                        :zone="timeZone"
                        v-model="effectiveDate"
                        input-class="form-control form-unit__input"
                        title="Select effective date"
                      ></datetime>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6" v-if="campaignTeamDonations.length < 2">
                  <div v-if="teamList.length > 0">
                    <div class="form-group form-unit form-unit--multiselect">
                      <label class="entities-data__label entities-data">
                        {{ $t("dashboard.edit_donate_team") }}
                      </label>
                      <multiselect
                        v-model="multiselectTeam"
                        :options="teamList"
                        label="text"
                        track-by="text"
                        :select-label="$t('dashboard.team_select_label', 'Press enter to select')"
                        :deselect-label="
                          $t('dashboard.team_deselect_label', 'Press enter to remove')
                        "
                        :selected-label="$t('dashboard.team_selected_label', 'Selected')"
                        :disabled="moreThanOneTeamDonation"
                        :multiple="false"
                      >
                      </multiselect>
                      <div class="form-help-grid">
                        <span v-show="moreThanOneTeamDonation" class="v-help is-warning">
                          {{
                            $t(
                              "template.team_donations_disabled_multiselect",
                              "Multiple teams selected!"
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 mb-3" v-else>
                  <a href="#" @click="tabs = 1">
                    {{ $t("dashboard.see_all_teams") }}
                  </a>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="entities-data__label entities-data">
                      {{ $t("dashboard.edit_donate_receipt_name") }}
                    </label>
                    <b-form-input v-model="editDonation.data.attributes.receipt_name">
                    </b-form-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="entities-data__label">
                      {{ $t("dashboard.edit_donate_dedication") }}
                    </label>
                    <b-form-input
                      v-model="editDonation.data.attributes.dedication"
                      :rows="2"
                    ></b-form-input>
                  </div>
                </div>
                <div v-if="allowOrgComment" class="col-12">
                  <div class="form-group">
                    <label class="entities-data__label">
                      {{ $t("dashboard.edit_donate_org_comment", "Comment") }}
                    </label>
                    <b-form-input v-model="editDonation.data.attributes.org_comment"></b-form-input>
                  </div>
                </div>
              </div>
              <div
                class="row"
                v-if="
                  isPladge(editDonation.data.attributes.bank_name) &&
                  (this.mode === 1 || this.mode === 2)
                "
              >
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="entities-data__label entities-data">{{
                      $t("dashboard.edit_donate_status")
                    }}</label>
                    <b-form-select v-model="editDonation.data.attributes.status">
                      <option
                        v-for="(status, index) in $options.PLEDGE_STATUS_LIST"
                        :value="status"
                        :key="index"
                      >
                        {{ status }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <p class="status-description">{{ getStatusMessage }}</p>
                </div>
              </div>

              <div class="row" v-if="premoderation">
                <div class="col-12 col-lg-6">
                  <div class="radio-bttn-group">
                    <div class="form-group form-unit form-group-bttn">
                      <input
                        id="on-review"
                        type="radio"
                        value="0"
                        v-model="editDonation.data.attributes.moderation_status"
                        :disabled="isCheck"
                      />
                      <label for="on-review">{{ $t("donors.donation_on_review") }}</label>
                    </div>

                    <div class="form-group form-unit form-group-bttn">
                      <input
                        id="approved"
                        type="radio"
                        value="1"
                        v-model="editDonation.data.attributes.moderation_status"
                        :disabled="isCheck"
                      />
                      <label for="approved">{{ $t("donors.donation_approved") }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="radio-bttn-group-text">
                    <span v-if="isCheck">
                      {{ $t("donors.donation_moderation_check_note") }}
                    </span>
                    <span v-else>
                      {{ $t("donors.donation_status_change_describe") }}
                    </span>
                  </div>
                </div>
              </div>

              <div
                class="row"
                v-if="unpublishedActivated && editDonation.data.attributes.donation_type === 'H'"
              >
                <div class="col-12 col-lg-6">
                  <div class="radio-bttn-group">
                    <div class="form-group form-unit form-group-bttn">
                      <input
                        id="unpublished"
                        v-model="editDonation.data.attributes.status"
                        type="radio"
                        name="publish"
                        value="OnHold"
                      />
                      <label for="unpublished">
                        {{ $t("dashboard.donation_unpublished") }}
                      </label>
                    </div>

                    <div class="form-group form-unit form-group-bttn">
                      <input
                        id="published"
                        v-model="editDonation.data.attributes.status"
                        type="radio"
                        name="publish"
                        :value="getPublishedStatus()"
                      />
                      <label for="published">
                        {{ $t("dashboard.donation_published") }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="radio-bttn-group-text">
                    <span>
                      {{ $t("dashboard.donation_unpublishable_status_change_describe") }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="wrapper-options">
                <button
                  type="button"
                  @click="showOptionaly = !showOptionaly"
                  class="show-hide-bttn"
                  name="button"
                >
                  {{
                    !showOptionaly
                      ? $t("dashboard.edit_donation_show_optionaly")
                      : $t("dashboard.edit_donation_hide_optionaly")
                  }}
                  <i
                    :class="!showOptionaly ? 'fa fa-angle-down' : 'fa fa-angle-up'"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>

              <div class="optionally-params" v-if="showOptionaly">
                <div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="entities-data__label entities-data">{{
                          $t("dashboard.edit_donate_billing_name")
                        }}</label>
                        <b-form-input
                          v-model="editDonation.data.attributes.billing_name"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="entities-data__label entities-data">{{
                          $t("dashboard.edit_donate_billing_last_name")
                        }}</label>
                        <b-form-input
                          v-model="editDonation.data.attributes.billing_last_name"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.edit_donate_phone") }}
                        </label>
                        <b-form-input v-model="editDonation.data.attributes.phone"></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label
                          class="entities-data__label entities-data"
                          :class="{
                            'entities-data__label--required':
                              editDonation.data.attributes.uk_gift_aid,
                          }"
                        >
                          {{ $t("dashboard.edit_donate_country") }}
                        </label>
                        <b-form-select
                          name="payment_country"
                          :required="editDonation.data.attributes.uk_gift_aid"
                          v-model="editDonation.data.attributes.payment_country"
                          v-validate="editDonation.data.attributes.uk_gift_aid && 'required'"
                          :class="{ 'is-danger': vErrors.has('payment_country') }"
                        >
                          <option
                            v-for="country in countryList"
                            :key="country.id"
                            :value="country.attributes.name"
                          >
                            {{ country.attributes.name }}
                          </option>
                        </b-form-select>
                        <div class="form-help-grid">
                          <span v-show="vErrors.has('payment_country')" class="v-help is-danger">
                            {{ $t("template.type_is_required", "Field is required") }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label
                          class="entities-data__label entities-data"
                          :class="{
                            'entities-data__label--required':
                              editDonation.data.attributes.uk_gift_aid,
                          }"
                        >
                          {{ $t("dashboard.edit_donate_city") }}
                        </label>
                        <b-form-input
                          name="payment_city"
                          :required="editDonation.data.attributes.uk_gift_aid"
                          v-model="editDonation.data.attributes.payment_city"
                          v-validate="editDonation.data.attributes.uk_gift_aid && 'required'"
                          :class="{ 'is-danger': vErrors.has('payment_city') }"
                        ></b-form-input>
                        <div class="form-help-grid">
                          <span v-show="vErrors.has('payment_city')" class="v-help is-danger">
                            {{ $t("template.type_is_required", "Field is required") }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label
                          class="entities-data__label entities-data"
                          :class="{
                            'entities-data__label--required':
                              editDonation.data.attributes.uk_gift_aid,
                          }"
                        >
                          {{ $t("dashboard.edit_donate_address") }}
                        </label>
                        <b-form-input
                          name="payment_address"
                          :required="editDonation.data.attributes.uk_gift_aid"
                          v-model="editDonation.data.attributes.payment_address"
                          v-validate="editDonation.data.attributes.uk_gift_aid && 'required'"
                          :class="{ 'is-danger': vErrors.has('payment_address') }"
                        ></b-form-input>
                        <div class="form-help-grid">
                          <span v-show="vErrors.has('payment_address')" class="v-help is-danger">
                            {{ $t("template.type_is_required", "Field is required") }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.add_donate_payment_address_2") }}
                        </label>
                        <b-form-input
                          v-model="editDonation.data.attributes.payment_address_2"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.add_donate_referrer") }}
                        </label>
                        <b-form-input
                          readonly
                          v-model="editDonation.data.attributes.referrer"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label
                          class="entities-data__label entities-data"
                          :class="{
                            'entities-data__label--required':
                              editDonation.data.attributes.uk_gift_aid,
                          }"
                        >
                          {{ $t("dashboard.add_donate_payment_state") }}
                        </label>
                        <b-form-input
                          name="payment_state"
                          :required="editDonation.data.attributes.uk_gift_aid"
                          v-model="editDonation.data.attributes.payment_state"
                          v-validate="editDonation.data.attributes.uk_gift_aid && 'required'"
                          :class="{ 'is-danger': vErrors.has('payment_state') }"
                        ></b-form-input>
                        <div class="form-help-grid">
                          <span v-show="vErrors.has('payment_state')" class="v-help is-danger">
                            {{ $t("template.type_is_required", "Field is required") }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6" v-if="stream.length > 0">
                      <div class="form-group form-unit--multiselect">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.edit_donate_stream", "Stream") }}
                        </label>
                        <multiselect
                          v-model="multiselectStream"
                          :options="stream"
                          :custom-label="(el) => `[${el.id}] ${el.title}`"
                          track-by="id"
                          :multiple="false"
                          :searchable="true"
                          :select-label="$t('dashboard.team_select_label', 'Press enter to select')"
                          :deselect-label="
                            $t('dashboard.team_deselect_label', 'Press enter to remove')
                          "
                          :selected-label="$t('dashboard.team_selected_label', 'Selected')"
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.edit_donate_category") }}
                        </label>
                        <b-form-input
                          v-model="editDonation.data.attributes.category"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label
                          class="entities-data__label entities-data"
                          :class="{
                            'entities-data__label--required':
                              editDonation.data.attributes.uk_gift_aid,
                          }"
                        >
                          {{ $t("dashboard.edit_donate_postcode") }}
                        </label>
                        <b-form-input
                          name="payment_postcode"
                          :required="editDonation.data.attributes.uk_gift_aid"
                          v-model="editDonation.data.attributes.payment_postcode"
                          v-validate="editDonation.data.attributes.uk_gift_aid && 'required'"
                          :class="{ 'is-danger': vErrors.has('payment_postcode') }"
                        ></b-form-input>
                        <div class="form-help-grid">
                          <span v-show="vErrors.has('payment_postcode')" class="v-help is-danger">
                            {{ $t("template.type_is_required", "Field is required") }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 align-self-center">
                      <div class="form-group form-unit--multiselect">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.edit_donate_tag", "Tag") }}
                        </label>
                        <b-form-select
                          v-model="editDonation.data.attributes.tag"
                          :options="tagOptions"
                        ></b-form-select>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 align-self-center">
                      <div class="form-group">
                        <b-form-checkbox
                          v-model="editDonation.data.attributes.uk_gift_aid"
                          disabled
                        >
                          {{ $t("dashboard.edit_donate_gift_aid", "Gift Aid (UK)") }}
                        </b-form-checkbox>
                      </div>
                    </div>

                    <div
                      v-if="(journalAdActivated || ticketActivated) && !lookUpName"
                      class="col-12 col-md-6"
                    >
                      <div class="form-group">
                        <label class="entities-data__label entities-data">
                          {{
                            ticketActivated
                              ? $t("dashboard.add_donate_external_resource_id_ticket", "Ticket Id")
                              : $t("dashboard.add_donate_external_resource_id")
                          }}
                        </label>
                        <b-form-input v-model="moduleDataId"></b-form-input>
                      </div>
                    </div>

                    <look-up-name
                      v-if="journalAdActivated && lookUpName"
                      ref="lookUpName"
                      :dataID="moduleDataId"
                    />

                    <div class="col-12 col-md-6" v-if="levels.length > 0">
                      <div class="form-group form-unit--multiselect">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.edit_donate_level", "Level") }}
                        </label>
                        <multiselect
                          v-model="multiselectLevel"
                          :options="levels"
                          :custom-label="(el) => `[${el.amount} ${currency}] ${el.title}`"
                          track-by="id"
                          :multiple="false"
                          :searchable="true"
                          :select-label="$t('dashboard.team_select_label', 'Press enter to select')"
                          :deselect-label="
                            $t('dashboard.team_deselect_label', 'Press enter to remove')
                          "
                          :selected-label="$t('dashboard.team_selected_label', 'Selected')"
                        >
                        </multiselect>
                      </div>
                    </div>
                  </div>

                  <div class="row" v-if="donationTypeD">
                    <div v-if="canBeCanceled" class="row w-100 my-2">
                      <div class="cancel-block" :class="{ 'pt-0': isCaptured }">
                        <b-alert show v-if="isCaptured" class="cancel-block__alert mb-2">
                          {{
                            $t(
                              "dashboard.donation_cancel_soft_notification",
                              "Donation will be canceled on Charidy platform but not funds are not yet refunded to the donor. Please proceed with refund on the corresponding gateway dashboard."
                            )
                          }}
                        </b-alert>

                        <div class="col-12 cancel-block__row">
                          <button
                            class="bttn bttn--sm bttn--orange"
                            type="button"
                            @click.prevent="cancelDonationSoft"
                          >
                            {{ $t("dashboard.donation_edit_cancel", "Cancel") }}
                          </button>

                          <p v-if="isPledge || isCreatedOffline">
                            {{
                              $t(
                                "dashboard.donation_edit_cancel_30_min",
                                "Will mark donation as canceled"
                              )
                            }}
                          </p>
                          <p v-else>
                            {{
                              $t(
                                "dashboard.donation_edit_cancel_soft",
                                "Will mark donation as canceled"
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="cancel-block" v-else>
                      <div
                        class="col-12 col-md-12"
                        v-for="(cancel, i) in chargeRequestType"
                        :key="`c${i}`"
                      >
                        <div v-if="cancel.status === true" class="cancel-block__row">
                          <button
                            type="button"
                            class="bttn bttn--sm bttn--orange"
                            :disabled="cancelDonate.data.attributes.type === cancel.value"
                            @click="changeCancel(cancel.value)"
                          >
                            {{ cancel.text }}
                          </button>
                          <p v-if="cancelDonate.data.attributes.type !== cancel.value">
                            {{ cancel.description }}
                            <span
                              v-if="
                                editDonation.data.attributes.bank_name.toLowerCase() === 'yaadpay'
                              "
                            >
                              {{ $t("dashboard.description_cancel_donate_yaadpay") }}
                            </span>
                            <span
                              v-if="
                                editDonation.data.attributes.bank_name.toLowerCase() === 'payme'
                              "
                            >
                              {{ $t("dashboard.description_cancel_donate_payme") }}
                            </span>
                          </p>
                          <div
                            v-if="cancelDonate.data.attributes.type === cancel.value"
                            class="confirm-block w-100"
                            :class="{
                              'flex-column align-items-center': cancel.value === 'cancel',
                            }"
                          >
                            <div class="w-100" v-if="cancel.value === 'cancel'">
                              <div class="form-group">
                                <label class="entities-data__label entities-data">
                                  {{ $t("dashboard.cancel_reason_label") }}
                                </label>
                                <b-form-select
                                  v-model="cancelDonate.data.attributes.reason"
                                  @change="cancelDonate.data.attributes.note = ''"
                                >
                                  <option value="duplicate">
                                    {{ $t("dashboard.cancel_reason_duplicate") }}
                                  </option>
                                  <option value="incorrect_amount">
                                    {{ $t("dashboard.cancel_reason_incorrect_amount") }}
                                  </option>
                                  <option value="suspected_fraud">
                                    {{ $t("dashboard.cancel_reason_suspected_fraud") }}
                                  </option>
                                  <option value="other">
                                    {{ $t("dashboard.cancel_reason_other") }}
                                  </option>
                                </b-form-select>
                              </div>
                              <div
                                v-if="cancelDonate.data.attributes.reason === 'other'"
                                class="form-group"
                              >
                                <b-form-input
                                  v-model="cancelDonate.data.attributes.note"
                                  :placeholder="$t('dashboard.cancel_reason_other_placeholder')"
                                ></b-form-input>
                              </div>
                            </div>

                            <b-form-input
                              v-if="cancel.value !== 'cancel'"
                              v-model="cancelDonate.data.attributes.note"
                              :placeholder="$t('dashboard.placeholder_cancel_donate')"
                            />
                            <p v-if="cancelDonateError">
                              {{ cancelDonateError }}
                            </p>
                            <div>
                              <button
                                type="button"
                                class="bttn bttn--sm bttn--orange"
                                :disabled="cancelDonateSending"
                                @click="submitCancelDonation()"
                              >
                                {{ $t("dashboard.confirm") }}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div v-else class="cancel-block__row">
                          <p v-if="cancel.status === 0">
                            {{ $t("dashboard.status_review_before_delete_" + cancel.value) }}
                            <span @click="deleteCancel(cancel)">
                              {{ $t("dashboard.status_review_delete_" + cancel.value) }}
                            </span>
                            {{ $t("dashboard.status_review_after_delete_" + cancel.value) }}
                          </p>
                          <p v-else-if="cancel.status === 1">
                            {{ $t("dashboard.status_accepted_" + cancel.value) }}
                          </p>
                          <p v-else-if="cancel.status === 2">
                            {{ $t("dashboard.status_declined_" + cancel.value) }}
                          </p>
                        </div>
                      </div>

                      <div v-if="chargeRequestType.length === 0" class="col-12 col-md-12">
                        {{ $t("dashboard.description_cancel_others_gateways") }}
                      </div>
                    </div>
                  </div>
                  <b-alert
                    v-if="isCaptured && donationTypeO && !(isPledge || isCreatedOffline)"
                    show
                  >
                    {{
                      $t(
                        "dashboard.donation_canceled_soft_notification",
                        "Donation is canceled on Charidy platform but not funds are not yet refunded to the donor. Please proceed with refund on the corresponding gateway dashboard."
                      )
                    }}
                  </b-alert>
                  <div class="row mt-5">
                    <div class="col-12">
                      <DonationUnitEmailBtns
                        :d-id="donationId"
                        :c-id="campaignid"
                        :d-attrs="editDonation.data.attributes"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <error v-if="errors" :errors="errors"></error>
            </form>
          </b-tab>
          <b-tab :title="$t('dashboard.campaign_team_donation')">
            <b-overlay :show="loadingCampaignTeamDonations" variant="info">
              <campaign-team-donation
                :c-id="campaignid"
                :d-id="donationId"
                :d-attrs="editDonation.data.attributes"
                :d-amount="editDonation.data.attributes.effective_amount"
                :d-charged-amount="editDonation.data.attributes.charged_amount"
                :d-currency-code="editDonation.data.attributes.currency_code"
                ref="CampaignTeamDonation"
                :hide="hideModal"
                :isValid.sync="teamDonationIsValid"
                @localDonationsChanged="localDonationsChanged"
                isEditing
              />
            </b-overlay>
          </b-tab>
          <b-tab
            v-if="journalAdActivated && !lookUpName"
            :title="$t('dashboard.journal_ad_pages_tab_title')"
          >
            <ad-page-journal-tab ref="adPageJournal" :module-id="moduleDataId" />
          </b-tab>
          <b-tab
            v-if="calendarActivated"
            :title="$t('dashboard.calendar_title', 'Calendar module')"
          >
            <calendar ref="CalendarForm" :module-id="moduleDataId" />
          </b-tab>
          <b-tab
            v-if="pledgeWithNotificationToPay"
            :title="$t('dashboard.pledge_notification_to_pay', 'Pledge Notification To Pay')"
          >
            <PledgeNotificationToPay
              :donationID="donationId"
              :donationAttributes="editDonation.data.attributes"
            />
          </b-tab>
          <b-tab v-if="showSubscriptionTab" :title="$t('dashboard.subscription', 'Subscription')">
            <RecurringResubscribe
              v-if="subscriptionCanceled && !subscriptionPaidOff"
              :donationID="donationId"
              :donationAttributes="editDonation.data.attributes"
            />

            <RecurringPayOff
              v-if="!subscriptionPaidOff"
              :donationID="donationId"
              :donationAttributes="editDonation.data.attributes"
            />

            <UpdateSubPaymentDetails
              :donationID="donationId"
              :campaignID="campaignid"
              :donationAttrs="editDonation.data.attributes"
            />
          </b-tab>
          <b-tab
            v-if="customDataList.length"
            :title="$t('dashboard.donation_tab_custom_fields', 'Donation form custom fields')"
          >
            <DonationUnitCustomFields :fields="customDataList" />
          </b-tab>
        </b-tabs>
      </b-overlay>

      <b-alert v-if="!teamDonationIsValid" show class="mt-4" variant="danger">
        {{
          $t(
            "dashboard.donations_to_teams_error",
            "Validation error: Check please tab Donation to the teams"
          )
        }}
      </b-alert>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import axios from "axios";
import urls from "@/constants/urls.js";
import error from "@/components/errors.vue";
import { notificationsMixin, emailGettersMixin } from "@/mixins";
import campaignTeamDonation from "./campaignTeamDonation.vue";
import adPageJournalTab from "./ad-page-journal/donation-tab.vue";
import LookUpName from "./modules/LookUpName.vue";
import Calendar from "./modules/calendar/Calendar.vue";
import PledgeNotificationToPay from "./pledgeNotificationToPay.vue";
import RecurringResubscribe from "./recurringResubscribe.vue";
import { getOfflineDonationSourceOptions } from "./shared";
import DonationUnitCustomFields from "./donationUnitCustomFields.vue";
import UpdateSubPaymentDetails from "./updateSubPaymentDetails.vue";
import DonationUnitEmailBtns from "./donationUnitEmailBtns.vue";
import { defineComponent } from "vue";
import RecurringPayOff from "./recurringPayOff.vue";

export default defineComponent({
  PLEDGE_STATUS_LIST: ["Pending", "Processed"],

  props: ["campaignid", "currentpage"],

  mixins: [notificationsMixin, emailGettersMixin],

  data() {
    return {
      tabs: 0,
      editDonation: {
        data: {
          attributes: {
            display_name: "",
            billing_name: "",
            dedication: "",
            category: "",
            team_id: 0,
            offline_donation_source: "",
            offline_donation_note: "",
            moderation_status: 0,
            bank: "",
            branch: "",
            account: "",
            effective_date: 0,
          },
          relationships: {},
        },
        included: [],
      },

      originStatus: "",
      moduleDataId: "",

      cancelDonate: {
        data: {
          attributes: {
            type: "",
            note: "",
            reason: "",
          },
        },
      },

      cancelDonateError: false,
      cancelDonateSending: false,
      donationId: 0,
      showOptionaly: false,
      premoderation: false,
      donationApproved: false,
      isCheck: false,
      errors: [],
      multiselectTeam: null,
      multiselectLevel: null,
      multiselectStream: null,
      loading: false,
      loadingDonation: true,
      loadingDonationOverlayVariant: "info",
      customDataList: [],

      teamDonationIsValid: false,
      moreThanOneTeamDonation: false,

      chargeRequestType: [],
      tagOptions: [
        { value: "", text: this.$t("dashboard.donation_no_tag", "No tag") },
        {
          value: "featured",
          text: this.$t("dashboard.donation_featured_tag", "Featured"),
        },
      ],
      offlineDonationSourceOptions: getOfflineDonationSourceOptions(this),
    };
  },

  components: {
    error: error,
    Multiselect,
    campaignTeamDonation,
    adPageJournalTab,
    LookUpName,
    Calendar,
    PledgeNotificationToPay,
    DonationUnitCustomFields,
    RecurringResubscribe,
    UpdateSubPaymentDetails,
    DonationUnitEmailBtns,
    RecurringPayOff,
  },

  watch: {
    multiselectTeam() {
      const ctdl = this.campaignTeamDonations.length;
      const tid = Number(this.multiselectTeam?.value) || 0;

      if (this.$refs.CampaignTeamDonation) {
        if (!tid) {
          this.$refs.CampaignTeamDonation.deleteLocal(0);
          return;
        }
        if (ctdl === 0) {
          this.$refs.CampaignTeamDonation.addLocal();
        }
        this.$refs.CampaignTeamDonation.changeTeam({
          i: 0,
          team_id: tid,
        });
      }
    },

    campaignTeamDonations() {
      this.setActiveTeam();
    },

    multiselectStream(value) {
      if (value) {
        this.editDonation.data.attributes.category = value.key;
      } else {
        this.editDonation.data.attributes.category = "";
      }
    },
  },

  computed: {
    ...mapState({
      teams: (state) => {
        return state.teams.allTeams;
      },

      includedDonations: (state) => {
        return state.donation.included;
      },

      teamList: (state) => {
        let arrTeams = state.teams.allTeams;

        let resultArr = [];

        for (var i = 0; i < arrTeams.length; i++) {
          let objTeam = {
            value: arrTeams[i].id,
            text: `[${arrTeams[i].id}] ${arrTeams[i].attributes.name}`,
          };

          resultArr.push(objTeam);
        }

        return resultArr;
      },

      countryList: (state) => {
        return state.account.countries;
      },

      orgId: function () {
        return localStorage.getItem("orgId");
      },

      getStatusMessage() {
        return this.editDonation.data.attributes.status === "Pending"
          ? this.$t("dashboard.edit_donate_pending_description")
          : this.$t("dashboard.edit_donate_processed_description");
      },

      mode: (state) => {
        return state.dashboard.campaignDashboard.data.attributes.mode;
      },

      unpublishedActivated: (state) => state.donation.unpublishedActivated,

      calendarActivated: (state) => state.calendar.activated,

      journalAdActivated: (state) => state.journalAd.activated,

      ticketActivated: (state) => state.ticket.activated,

      campaignTeamDonations: (state) => state.campaignTeamDonation.campaignTeamDonations,

      levels: (state) => Object.values(state.campaign.levels),
      stream: (state) => Object.values(state.campaign.streams),

      loadingCampaignTeamDonations: (state) =>
        state.campaignTeamDonation.loading.campaignTeamDonation,
      timeZone: (state) => state.account.account.data.attributes.timezone,
      currency: (state) => state.dashboard.campaignDashboard.data.attributes.currency_code,
      allowDirectlyCancelSubscription: (state) => state.account.allowDirectlyCancelSubscription,
      metas: (state) => state.campaign.metas,
    }),

    allowOrgComment() {
      return this.metas?.allow_org_comment_donations?.value || false;
    },

    lookUpName() {
      return this.metas?.custom_donate_button_preform?.form === "lookup_name";
    },

    pledgeWithNotificationToPay() {
      const { value } = this.metas?.pledge_with_notification_to_pay || {};

      return (
        (value || false) &&
        (this.editDonation?.data?.attributes?.bank_name?.includes("pledge") ||
          this.isCreatedOffline)
      );
    },

    subscriptionPaidOff() {
      // WARN: hide for now with MR the section to PayOff the subscription also to ReSubscribe
      // const { recurring_paid_off, recurring_period, bank_name } =
      //   this.editDonation.data.attributes || {};
      // return recurring_period > 0 && recurring_paid_off && bank_name === "stripe";
      return true;
    },

    subscriptionCanceled() {
      // WARN: hide for now with MR the section to PayOff the subscription also to ReSubscribe
      // const { recurring_canceled, recurring_period, bank_name } =
      //   this.editDonation.data.attributes || {};
      // return recurring_period > 0 && recurring_canceled && bank_name === "stripe";
      return false;
    },

    showSubscriptionTab() {
      const { recurring_period, bank_name } = this.editDonation.data.attributes || {};
      return recurring_period > 0 && bank_name === "stripe";
    },

    showEeffectiveDate() {
      const allowlist = ["check"];
      return allowlist.includes(this.editDonation.data.attributes.bank_name);
    },

    bankBranchAccountCanBeShowed() {
      const allowlist = [
        "credit_card",
        "check",
        "bank_transfer",
        "recurring_credit_card",
        "recurring_bank_transfer",
      ];
      return allowlist.some((v) => v === this.editDonation.data.attributes.offline_donation_source);
    },
    isCreatedOffline() {
      return this.editDonation.data?.attributes?.bank_name === "check";
    },
    isPledge() {
      return this.editDonation.data?.attributes?.bank_name?.includes("pledge");
    },
    effectiveDate: {
      get() {
        const date = this.editDonation.data.attributes.effective_date || "";
        const fdate = moment(date * 1000).toISOString();

        return date && fdate;
      },
      set(val) {
        this.editDonation.data.attributes.effective_date = new Date(val).getTime() / 1000;
      },
    },
    donationTypeD() {
      return this.editDonation?.data?.attributes?.donation_type?.toLowerCase() === "d";
    },
    donationTypeO() {
      return this.editDonation?.data?.attributes?.donation_type?.toLowerCase() === "o";
    },
    isCaptured() {
      return this.editDonation?.data?.attributes?.captured || false;
    },
    canBeCanceled() {
      return this.editDonation?.data?.attributes?.can_be_canceled || false;
    },
  },

  methods: {
    ...mapActions({
      publishDonations: "publishDonations",
    }),

    getPublishedStatus() {
      const statuses = ["Processed", "Authorized"];

      if (statuses.includes(this.originStatus)) {
        return this.originStatus;
      }
      return "Processed";
    },

    isPladge(bn) {
      return bn === "pledge" || bn === "pledgeonly" || bn === "fund";
    },

    changeCancel: function (value) {
      this.cancelDonate.data.attributes.type = value;
      this.cancelDonate.data.attributes.note = "";
      this.cancelDonate.data.attributes.reason = "duplicate";
    },

    submitCancelDonation: function () {
      this.cancelDonateError = false;
      this.cancelDonateSending = true;
      this.cancelDonate.data.attributes.donation_id = parseInt(this.donationId);
      axios
        .post(
          urls.cancelDonate
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignid)
            .replace(":donationId", this.donationId),
          this.cancelDonate
        )
        .then((response) => {
          this.setNewIncluded(
            response.data.data.relationships.donation_change_requests.data,
            response.data.included
          );
          this.cancelDonateSending = false;
          this.changeCancel("");
        })
        .catch(() => {
          this.cancelDonateSending = false;
        });
    },

    deleteCancel(val) {
      let message = this.$t("dashboard.confirm_delete_cancel_donate");
      let options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        axios
          .delete(
            urls.cancelDonateDelete
              .replace(":orgId", this.orgId)
              .replace(":campaignId", this.campaignid)
              .replace(":donationId", this.donationId)
              .replace(":id", val.id)
          )
          .then((response) => {
            this.setNewIncluded(
              response.data.data.relationships.donation_change_requests.data,
              response.data.included
            );
          });
      });
    },

    setNewIncluded: function (rel, included = []) {
      let status = [];
      rel.forEach((el) => {
        const arr = included.filter((includ) => {
          return includ.type === el.type && includ.id === el.id;
        });
        if (arr.length > 0) {
          status.push(arr[0]);
        }
      });
      this.chargeRequestType.forEach((cancel) => {
        cancel.status = true;
      });
      status.forEach((el) => {
        this.chargeRequestType.forEach((cancel) => {
          if (cancel.value === el.attributes.type) {
            cancel.status = el.attributes.status;
            cancel.id = el.id;
          }
        });
      });
    },

    setIncluded: function (rel) {
      let status = [];
      rel.forEach((el) => {
        const arr = this.includedDonations.filter((includ) => {
          return includ.type === el.type && includ.id === el.id;
        });
        if (arr.length > 0) {
          status.push(arr[0]);
        }
      });
      status.forEach((el) => {
        this.chargeRequestType.forEach((cancel) => {
          if (cancel.value === el.attributes.type) {
            cancel.status = el.attributes.status;
            cancel.id = el.id;
          }
        });
      });
    },

    async openEditModal(donateFromList, premoderation) {
      const oid = localStorage.getItem("orgId");
      this.$root.$emit("bv::show::modal", "editDonation");
      this.loadingDonation = true;
      this.donationId = donateFromList.id;
      try {
        const { data } = await axios.get(
          urls.loadDonation
            .replace(":oid", oid)
            .replace(":cid", this.campaignid)
            .replace(":id", this.donationId)
        );
        const donate = data.data;

        this.errors = [];
        this.premoderation = premoderation;
        this.donationId = donate.id;
        this.isCheck = donate.attributes.bank_name === "check";
        this.editDonation.data.attributes = donate.attributes;
        this.originStatus = donate.attributes.status;
        this.moduleDataId = donate.attributes.module_data_id;

        const includedObject = (this.includedDonations || []).reduce((res, el) => {
          let group = res[`${el.type}`];

          if (group) {
            group.push(el);
          } else {
            group = [el];
          }

          res[`${el.type}`] = group;

          return res;
        }, {});

        if (includedObject.selected_level?.length) {
          const [first] = donate.relationships.selected_levels.data;
          const selectedLevelRelationshipId = first?.id;

          if (selectedLevelRelationshipId) {
            this.multiselectLevel = this.levels.find(
              (el) => el.id === String(selectedLevelRelationshipId)
            );
          }
        }

        if (donate.attributes.category) {
          this.multiselectStream = this.stream.find((el) => el.key === donate.attributes.category);
        }

        const attrs = this.editDonation.data.attributes;

        this.chargeRequestType.push({
          text: this.$t("dashboard.cancel", "Cancel"),
          value: "cancel",
          description: this.$t("dashboard.cancel_description", "Request cancel of this donation"),
          status: true,
        });
        if (donate.attributes.bank_name.includes("stripe")) {
          if (attrs.recurring_period > 0 && !attrs.recurring_canceled) {
            this.chargeRequestType.push({
              text: this.$t("dashboard.cancel_subscription"),
              value: "cancel_subscription",
              description: this.$t("dashboard.cancel_subscription_description"),
              status: true,
            });
          }

          if (["authorized", "processed"].includes(attrs.status.toLowerCase()) && this.isCaptured) {
            this.chargeRequestType.push({
              text: this.$t("dashboard.cancel_refund"),
              value: "cancel_refund",
              description: this.$t("dashboard.cancel_refund_description"),
              status: true,
            });
          }
        }

        this.setIncluded(donate.relationships.donation_change_requests.data);
        this.setIncluded(donate.relationships.custom_data_list.data);
        this.customDataList = donate.relationships.custom_data_list.data;

        this.showOptionaly = false;
        this.loadingDonation = false;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        this.loadingDonationOverlayVariant = "danger";
        return;
      }
    },

    setActiveTeam() {
      const ctdl = this.campaignTeamDonations.length;
      if (ctdl === 1 && this.teamList.length) {
        this.multiselectTeam = this.teamList.find(
          (el) => Number(el.value) === this.campaignTeamDonations[0].team_id
        );
        return;
      }

      const selectedTeam = this.teamList.filter(
        (el) => el.value == this.editDonation.data.attributes.team_id
      );

      if (selectedTeam.length > 0) {
        this.multiselectTeam = selectedTeam[0];
      } else {
        this.multiselectTeam = null;
      }
    },

    clearData() {
      this.chargeRequestType = [];
      this.customDataList = [];
      this.loadingDonation = false;
      this.loadingDonationOverlayVariant = "info";
      this.moduleDataId = "";
      this.multiselectLevel = null;
      this.multiselectTeam = null;
      this.multiselectStream = null;

      this.cancelDonate = {
        data: {
          attributes: {
            type: "",
            note: "",
            reason: "",
          },
        },
      };

      this.editDonation = {
        data: {
          attributes: {
            display_name: "",
            billing_name: "",
            dedication: "",
            category: "",
            team_id: 0,
            offline_donation_source: "",
            offline_donation_note: "",
            moderation_status: 0,
            bank: "",
            branch: "",
            account: "",
            effective_date: 0,
          },
        },
      };
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "editDonation");
      this.$emit("hide");
    },

    buildRelations() {
      const relationships = {};
      const included = [];

      if (this.multiselectLevel) {
        relationships["selected_levels"] = {
          data: [
            {
              id: String(this.multiselectLevel.id),
              type: "selected_level",
            },
          ],
        };

        included.push({
          type: "selected_level",
          id: String(this.multiselectLevel.id),
          attributes: {
            count: 1,
          },
        });
      } else {
        relationships["selected_levels"] = {
          data: [],
        };
      }

      if (Object.keys(relationships).length) {
        this.editDonation.data.relationships = relationships;
      }
      if (included.length) {
        this.editDonation.included = included;
      }
    },

    editDonate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          this.errors = [];

          this.buildRelations();

          this.editDonation.data.attributes.moderation_status = parseInt(
            this.editDonation.data.attributes.moderation_status
          );
          this.editDonation.data.attributes.module_data_id = Number(this.moduleDataId);
          this.editDonation.data.attributes.email = this.$_emailGettersMixin_getStaticEmail(
            this.editDonation.data
          )[1];

          new Promise((resolve) => {
            if (this.$refs.adPageJournal) {
              this.$refs.adPageJournal
                .submit()
                .then(resolve)
                .catch(() => resolve(null));
              return;
            }
            if (this.$refs.CalendarForm) {
              this.$refs.CalendarForm.submit()
                .then(resolve)
                .catch(() => resolve(null));
              return;
            }
            if (this.$refs.lookUpName) {
              this.$refs.lookUpName
                .submit()
                .then(({ data }) => {
                  resolve(data.data.attributes.id);
                })
                .catch(() => resolve(null));
              return;
            }
            resolve(null);
          }).then((mID) => {
            if (this.moduleDataId !== mID && mID !== null) {
              this.editDonation.data.attributes.module_data_id = Number(mID);
            }

            delete this.editDonation.data.attributes.team_id_list;

            axios
              .patch(
                urls.putDonation
                  .replace(":orgId", this.orgId)
                  .replace(":campaignId", this.campaignid) +
                  "/" +
                  this.donationId,
                this.editDonation
              )
              .then(async () => {
                return Promise.allSettled([
                  () => this.$store.dispatch("getUnpublishedDonations", [this.campaignid]),
                  () =>
                    this.$store.dispatch("getCampaignDonation", {
                      id: this.campaignid,
                      setting: `?page=${this.currentpage}&limit=50&extend=donation_receipt_id`,
                    }),
                  () =>
                    this.$store.dispatch("getPendingCampaignDonation", {
                      id: this.campaignid,
                      setting: `?moderation_status=0&page=${this.currentpage}&limit=50&extend=donation_receipt_id`,
                    }),
                ]);
              })
              .then(() => {
                this.$_notificationsMixin_makeToast(
                  "Donation",
                  this.$t("dashboard.edit_success_edit"),
                  "success"
                );
                this.hideModal();
              })
              .catch((e) => {
                this.$_notificationsMixin_handleCatch(e);
              })
              .finally(() => {
                this.loading = false;
              });
          });

          this.$refs.CampaignTeamDonation.save();
        }
      });
    },

    cancelDonationSoft() {
      let message = this.$t("dashboard.confirm_cancel_donate", "Please confirm");
      let options = {
        okText: this.$t("account.confirm_cancelation_ok", "Yes cancel"),
        cancelText: this.$t("account.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        axios
          .post(
            urls.cancelDonateSoft
              .replace(":oid", this.orgId)
              .replace(":cid", this.campaignid)
              .replace(":did", this.donationId)
          )
          .then(() => {
            this.$store.dispatch("getCampaignDonation", {
              id: this.campaignid,
              setting: `?page=${this.currentpage}&limit=50&extend=donation_receipt_id`,
            });
            this.hideModal();
          })
          .catch((e) => {
            this.$_notificationsMixin_handleCatch(e);
          });
      });
    },

    localDonationsChanged(donations) {
      this.moreThanOneTeamDonation = donations.length > 1;
    },
  },
});
</script>

<style lang="scss" scoped>
.cancel-block {
  display: block;
  border-radius: 5px;
  border: 1px solid red;
  padding: 10px 0px;
  margin: 0 15px;
  width: 100%;
  &__alert {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &__row {
    display: flex;
    align-items: center;
    width: 100%;
    > button {
      margin-inline: 15px;
    }
    p {
      margin: 0;
    }
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .confirm-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    input {
      margin-inline: 15px;
    }
  }
}

.entities-data {
  &__label {
    text-transform: uppercase;
    font-size: 14px;
    color: #414359;
    line-height: 1;
    &--required {
      padding-right: 10px;
      position: relative;
      &:after {
        content: "*";
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        color: red;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

.status-description {
  margin-top: 25px;
}

.entities-add {
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0 15px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  color: #20434e;
  background-color: #ffffff;
}

.wrapper-options {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eaf4ff;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
  }
  .show-hide-bttn {
    width: auto;
    position: relative;
    z-index: 2;
    &:focus {
      outline: 0;
    }
  }
}

.radio-bttn-group {
  display: flex;
  padding: 10px 0;
  &-text {
    padding: 10px 0;
  }
  .form-group-bttn {
    margin: 0;
    display: flex;
    width: 50%;
    position: relative;
    height: 50px;
    input {
      width: 1px;
      height: 1px;
      overflow: hidden;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    label {
      margin: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-family: "VitoExtended-Bold";
      color: #107598;
      cursor: pointer;
    }
    input:checked + label {
      background-color: #107598;
      color: #ffffff;
    }
    input:disabled + label {
      opacity: 0.3;
      pointer-events: none;
    }
    &:first-of-type {
      label {
        border-top: 1px solid #107598;
        border-bottom: 1px solid #107598;
        border-left: 1px solid #107598;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    &:last-of-type {
      label {
        border-top: 1px solid #107598;
        border-bottom: 1px solid #107598;
        border-right: 1px solid #107598;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
.is-danger {
  border-color: #f26e66;
  color: #f26e66;
}
.is-warning {
  border-color: $warning;
  color: $warning;
}
.optionally-nav-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
  .nav-item {
    margin-bottom: -2px;
    .nav-link {
      border: 3px solid transparent;
      &.active {
        background-color: #fff;
        border-color: #fff #fff #107598;
      }
    }
  }
}
.form-help-grid {
  padding-top: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 5px 0px;
  .v-help {
    font-size: 14px;
    font-family: $vito-light;
    position: relative;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
  }
}
</style>
