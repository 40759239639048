<template>
  <div>
    <div class="d-flex justify-content-center">
      <button v-if="!success" class="bttn bttn--lg bttn--orange" @click="send" :disabled="loading">
        <b-spinner v-if="loading" small></b-spinner>
        {{ btnTitle }}
      </button>
      <b-alert v-else variant="success" show>
        {{
          $t(
            "dashboard.sub_pay_off_success",
            "Subscription Paid Off ({currency}{remaining_amount})",
            result
          )
        }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls";
import { notificationsMixin } from "@/mixins";

export default {
  props: {
    donationID: {
      type: [Number, String],
      default: 0,
    },
    donationAttributes: {
      type: Object,
      default: () => {},
    },
  },

  mixins: [notificationsMixin],

  data() {
    return {
      success: false,
      loading: false,
      result: {
        remaining_amount: 0,
        currency: "",
      },
    };
  },

  computed: {
    orgId() {
      return localStorage.getItem("orgId");
    },
    btnTitle() {
      return this.$t("dashboard.sub_pay_off", "Pay off");
    },
  },

  methods: {
    send() {
      this.loading = true;
      axios
        .post(
          urls.recurringPayOff(this.orgId, this.donationAttributes.campaign_id, this.donationID)
        )
        .then(({ data }) => {
          this.$_notificationsMixin_makeToast("Success", "", "success");
          this.success = true;
          this.result = data.data;
        })
        .catch((e) => {
          this.$_notificationsMixin_handleCatch(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
